<template>
    <h1>
      Cargando...
    </h1>
  </template>
  <script>
  import Cookies from 'js-cookie';
  export default {
      data(){
      },
      mounted () {
        this.deleteCokie()
        this.redirect()
      },
      methods:{
        deleteCokie(){
            Cookies.remove('token');
        },
        redirect(){
            const ruta = `${process.env.VUE_APP_URL_FULL_SMART}/login`
            window.open(ruta,'_self')
          },
      }
  }
  </script>